import React, { useState } from 'react';
import { faChevronRight, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from 'api/api';

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      const response = await api.post('/seller/reset-password', { email });

      if (response.data && response.data[0]?.success) {
        setIsSuccess(true);
        setResponseMessage(response.data[0].message);
      } else {
        setIsSuccess(false);
        setResponseMessage('Failed to send reset instructions. Please try again.');
      }
    } catch (error) {
      console.error('Error sending reset password email:', error);
      setIsSuccess(false);
      setResponseMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRetry = () => {
    setEmail('');
    setResponseMessage(null);
    setIsSuccess(null);
  };

  const handleLogin = () => {
    navigate('/'); // Navigate to the login page internally without refreshing
  };

  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div className={classNames('text-center', { 'mb-6': !(layout === 'split') })}>
        {!responseMessage ? (
          <>
            <h4 className="text-body-highlight">Forgot your password?</h4>
            <p className="text-body-tertiary mb-5">
              Enter your email below and we will send <br className="d-sm-none" />
              you a reset link
            </p>
            <Form className="d-flex align-items-center mb-5" onSubmit={handleSubmit}>
              <Form.Control
                type="email"
                id="email"
                className="flex-1"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSubmitting}
              />
              <Button
                type="submit"
                variant="primary"
                className="ms-2"
                endIcon={<FontAwesomeIcon icon={faChevronRight} className="ms-2" />}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send'}
              </Button>
            </Form>
          </>
        ) : (
          <div className="text-center">
            <FontAwesomeIcon
              icon={isSuccess ? faCheckCircle : faTimesCircle}
              className={isSuccess ? 'text-success' : 'text-danger'}
              size="8x"
            />
            <h4 className="text-body-highlight mt-4">{responseMessage}</h4>
            {!isSuccess && (
              <div className="mt-3">
                <Button variant="link" onClick={handleRetry} className="me-3">
                  Retry
                </Button>
                <Button variant="link" onClick={handleLogin}>
                  Login
                </Button>
              </div>
            )}
            {isSuccess && (
              <div className="mt-3">
                <Button variant="link" onClick={handleLogin}>
                  Login
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
