import { CSSProperties, ChangeEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import imageIcon from 'assets/img/icons/image-icon.png';
import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';

interface CoverUploadProps {
  src?: string;
  className?: string;
  gradient?: CSSProperties;
  onChange?: (file: File | null) => void; // Update onChange to pass the file
}

const CoverUpload = ({
  src,
  gradient,
  onChange,
  className
}: CoverUploadProps) => {
  const [image, setImage] = useState<File | null>(null);
  const { config: { isDark } } = useAppContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    setImage(selectedFile);
    if (onChange) {
      onChange(selectedFile); // Pass the selected file to the parent
    }
  };

  // Dynamic styles for the button based on the theme
  const buttonStyles: CSSProperties = {
    backgroundColor: isDark ? '#333' : '#fff',
    color: isDark ? '#fff' : '#333',
    padding: '10px 20px !important',
    borderRadius: '5px',
    border: 'none',
    boxShadow: isDark
      ? '0px 4px 6px rgba(0, 0, 0, 0.3)'
      : '0px 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };

  return (
    <div
      className={classNames(
        className,
        'bg-holder rounded-top hover-actions-trigger position-relative'
      )}
      style={{
        backgroundImage: `${gradient ? gradient + ',' : ''}url(${
          image ? URL.createObjectURL(image) : src
        })`,
        height: '100%', // Ensure it takes full height
      }}
      onClick={(e) => e.stopPropagation()} // Prevent click on banner from triggering upload
    >
      <input
        className="d-none"
        id="coverFile"
        type="file"
        accept="image/*"
        onChange={handleChange}
      />
      <div className="hover-actions position-absolute end-0 bottom-0 pe-3 pb-3">
        <Button
          variant="link"
          style={buttonStyles}
          onClick={() => document.getElementById('coverFile')?.click()} // Trigger file input click on button click
        >
          <FontAwesomeIcon icon={faCamera} className="me-2" />
          Change Cover Image
        </Button>
      </div>
    </div>
  );
};

export default CoverUpload;
