import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import api from 'api/api';

interface Seller {
  entity_id: string;
  name: string;
  email: string;
  shop_url: string;
  shop_title: string;
  logo_pic: string;
  company_locality: string;
  contact_number: string;
  billing_full: string;
  billing_firstname: string;
  billing_lastname: string;
  billing_telephone: string;
  billing_postcode: string;
  billing_country_id: string;
  billing_region: string;
  billing_region_id: string;
  billing_street: string;
  billing_city: string;
  billing_company: string;
  [key: string]: any; // Add more fields as needed
}

interface AuthContextProps {
  isAuthenticated: boolean;
  seller: Seller | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setSeller: React.Dispatch<React.SetStateAction<Seller | null>>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [seller, setSeller] = useState<Seller | null>(null);

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('authToken');
    const storedSeller = localStorage.getItem('seller');

    if (token && storedSeller) {
      // If a token and seller data are found, authenticate the user
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setSeller(JSON.parse(storedSeller));
      setIsAuthenticated(true);
    } else {
      // Set the anonymous token if no token is found
      api.defaults.headers.common['Authorization'] = `Bearer cl9o82plb1s2ap8gx3m40kyjw3xldpwz`;
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await api.post('/seller/login', {
        username: email,
        password: password,
      });
      const { seller, token } = response.data[0];
      localStorage.setItem('authToken', token);
      localStorage.setItem('seller', JSON.stringify(seller));
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setSeller(seller);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await api.post('/seller/logout');
      localStorage.removeItem('authToken');
      localStorage.removeItem('seller');
      api.defaults.headers.common['Authorization'] = `Bearer cl9o82plb1s2ap8gx3m40kyjw3xldpwz`;
      setSeller(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, seller, login, logout, setSeller, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
