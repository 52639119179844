import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './CustomCascader.css';
import 'react-loading-skeleton/dist/skeleton.css';

interface Category {
  id: string;
  parent_id: string;
  name: string;
}

interface CustomCascaderProps {
  fetchCategories: (parentId: string | null, level: number) => Promise<Category[]>;
  onCategorySelected: (selectedCategory: {
    level1: Category | null;
    level2: Category | null;
    level3: Category | null;
  }) => void;
  initialSelection?: {
    level1: Category | null;
    level2: Category | null;
    level3: Category | null;
  };
}

const CustomCascader: React.FC<CustomCascaderProps> = ({
  fetchCategories,
  onCategorySelected,
  initialSelection,
}) => {
  const [selectedCategoryPath, setSelectedCategoryPath] = useState<string[]>([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{
    level1: Category | null;
    level2: Category | null;
    level3: Category | null;
  }>({ level1: null, level2: null, level3: null });
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [levelOneCategories, setLevelOneCategories] = useState<Category[]>([]);
  const [levelTwoCategories, setLevelTwoCategories] = useState<Category[]>([]);
  const [levelThreeCategories, setLevelThreeCategories] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState<string[]>(['', '', '']);
  const [loadingLevels, setLoadingLevels] = useState({ level1: false, level2: false, level3: false });
  const [currentLevel, setCurrentLevel] = useState(1);
  const staticLevelOneCategories = function() {
    const level1Categories = [
      {
          "id": "5",
          "parent_id": "2",
          "name": "Électroménager"
      },
      {
          "id": "33",
          "parent_id": "2",
          "name": "Cuisine"
      },
      {
          "id": "37",
          "parent_id": "2",
          "name": "Meuble et décoration"
      },
      {
          "id": "53",
          "parent_id": "2",
          "name": "Informatique "
      },
      {
          "id": "123",
          "parent_id": "2",
          "name": "Beauté et Bien-être"
      },
      {
          "id": "219",
          "parent_id": "2",
          "name": "Jardin"
      },
      {
          "id": "220",
          "parent_id": "2",
          "name": "Bricolage"
      },
      {
          "id": "224",
          "parent_id": "2",
          "name": "Auto & moto"
      },
      {
          "id": "259",
          "parent_id": "2",
          "name": "Mode"
      },
      {
          "id": "3396",
          "parent_id": "2",
          "name": "Hygiène et Santé"
      },
      {
          "id": "3690",
          "parent_id": "2",
          "name": "Sport et loisir "
      },
      {
          "id": "3789",
          "parent_id": "2",
          "name": "Jeux et Jouets"
      },
      {
          "id": "4164",
          "parent_id": "2",
          "name": "Animalerie"
      },
      {
          "id": "4192",
          "parent_id": "2",
          "name": "Fournitures de bureau"
      },
      {
          "id": "4327",
          "parent_id": "2",
          "name": "Epicerie"
      },
      {
          "id": "4449",
          "parent_id": "2",
          "name": "High-Tech"
      }
    ];
    setLevelOneCategories(level1Categories);
  }
  useEffect(() => {
    const fetchAndPopulateLevelsSequentially = async () => {
      if (initialSelection) {
        try {
          // Fetch and populate level 1
          if (initialSelection.level1) {
          staticLevelOneCategories();
  
            // Fetch and populate level 2
            if (initialSelection.level2) {
              const level2Categories = await fetchCategoriesByParentId(initialSelection.level1.id, 2);
              setLevelTwoCategories(level2Categories);
  
              // Fetch and populate level 3
              if (initialSelection.level3) {
                const level3Categories = await fetchCategoriesByParentId(initialSelection.level2.id, 3);
                setLevelThreeCategories(level3Categories);
              }
            }
          } else {
            // Fetch level 1 categories if no initial selection
            staticLevelOneCategories();
          }
  
          // Set the selected path and categories based on the initial selection
          const newSelectedPath = [
            initialSelection.level1?.name || '',
            initialSelection.level2?.name || '',
            initialSelection.level3?.name || '',
          ].filter(name => name);
  
          const newSelectedIds = [
            initialSelection.level1?.id || '',
            initialSelection.level2?.id || '',
            initialSelection.level3?.id || '',
          ].filter(id => id);
  
          setSelectedCategoryPath(newSelectedPath);
          setSelectedCategoryIds(newSelectedIds);
          setSelectedCategories(initialSelection);
  
          // Call the onCategorySelected callback with the initial selection
          onCategorySelected(initialSelection);
        } catch (error) {
          console.error('Error fetching and populating categories:', error);
        }
      } else {
        // Fetch level 1 categories initially if there is no selection
        staticLevelOneCategories();
      }
    };
  
    fetchAndPopulateLevelsSequentially();
  }, [initialSelection]);

  useEffect(() => {
    if (dropdownOpen && selectedCategoryPath.length === 0) {
      staticLevelOneCategories(); // Fetch level 1 categories when dropdown opens if not already selected
    }
  }, [dropdownOpen]);

  const fetchCategoriesByParentId = async (parentId: string | null, level: number): Promise<Category[]> => {
    setLoadingLevels(prev => ({ ...prev, [`level${level}`]: true }));
  
    try {
      const categories = await fetchCategories(parentId, level);
      if (level === 1) {
        setLevelOneCategories(categories);
      } else if (level === 2) {
        setLevelTwoCategories(categories);
      } else if (level === 3) {
        setLevelThreeCategories(categories);
      }
      return categories;
    } catch (error) {
      console.error('Error fetching categories:', error);
      return [];
    } finally {
      setLoadingLevels(prev => ({ ...prev, [`level${level}`]: false }));
    }
  };

  const handleCategoryClick = (category: Category, level: number) => {
    const newSelectedPath = [...selectedCategoryPath];
    const newSelectedIds = [...selectedCategoryIds];

    newSelectedPath[level - 1] = category.name;
    newSelectedIds[level - 1] = category.id;

    setSelectedCategoryPath(newSelectedPath);
    setSelectedCategoryIds(newSelectedIds);

    // Update the selected categories state
    const newSelectedCategories = { ...selectedCategories };
    newSelectedCategories[`level${level}` as 'level1' | 'level2' | 'level3'] = category;
    setSelectedCategories(newSelectedCategories);

    if (level === 1) {
      setCurrentLevel(2);
      fetchCategoriesByParentId(category.id, 2);
      setLevelTwoCategories([]); // Clear subsequent levels when a new category is selected at level 1
      setLevelThreeCategories([]);
    } else if (level === 2) {
      setCurrentLevel(3);
      fetchCategoriesByParentId(category.id, 3);
      setLevelThreeCategories([]); // Clear subsequent level when a new category is selected at level 2
    } else if (level === 3) {
      setDropdownOpen(false);
      onCategorySelected(newSelectedCategories); // Pass the entire selected categories object
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>, level: number) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[level - 1] = e.target.value;
    setSearchTerm(newSearchTerms);
  };

  const handleDropdownClose = () => {
    if (selectedCategoryPath.length < 3) {
      setSelectedCategoryPath([]);
      setSelectedCategoryIds([]);
      setSearchTerm(['', '', '']);
      setCurrentLevel(1);
      setSelectedCategories({ level1: null, level2: null, level3: null }); // Reset the selected categories
    }
    setDropdownOpen(false);
  };

  const filterCategories = (categories: Category[], searchTerm: string) => {
    return categories.filter((cat) =>
      cat.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <div className="mb-5 custom-cascader">
      <InputGroup onClick={() => setDropdownOpen(true)}>
        <Form.Control
          placeholder="Select a category"
          value={selectedCategoryPath.join(' > ')}
          readOnly
        />
      </InputGroup>
      {dropdownOpen && (
        <>
          <div className="cascader-backdrop" onClick={handleDropdownClose} />
          <div className="cascader-dropdown">
            <div className="cascader-menus">
              <div className="cascader-menu">
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchTerm[0]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e, 1)}
                />
                <ul>
                  {loadingLevels.level1
                    ? Array(5)
                        .fill(null)
                        .map((_, index) => (
                          <li key={`skeleton-${index}`}>
                            <Skeleton
                              height={20}
                              baseColor="var(--phoenix-secondary-bg)"
                              highlightColor="var(--phoenix-emphasis-bg)"
                            />
                          </li>
                        ))
                    : filterCategories(levelOneCategories, searchTerm[0]).map((cat) => (
                        <li
                          key={cat.id}
                          onClick={() => handleCategoryClick(cat, 1)}
                          className={selectedCategoryIds[0] === cat.id ? 'selected' : ''}
                        >
                          {cat.name} <FontAwesomeIcon icon={faAngleRight} />
                        </li>
                      ))}
                </ul>
              </div>
              {currentLevel >= 2 && (
                <div className="cascader-menu">
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchTerm[1]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e, 2)}
                  />
                  <ul>
                    {loadingLevels.level2
                      ? Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <li key={`skeleton-${index}`}>
                              <Skeleton
                                height={20}
                                baseColor="var(--phoenix-secondary-bg)"
                                highlightColor="var(--phoenix-emphasis-bg)"
                              />
                            </li>
                          ))
                      : filterCategories(levelTwoCategories, searchTerm[1]).map((cat) => (
                          <li
                            key={cat.id}
                            onClick={() => handleCategoryClick(cat, 2)}
                            className={selectedCategoryIds[1] === cat.id ? 'selected' : ''}
                          >
                            {cat.name} <FontAwesomeIcon icon={faAngleRight} />
                          </li>
                        ))}
                  </ul>
                </div>
              )}
              {currentLevel >= 3 && (
                <div className="cascader-menu">
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={searchTerm[2]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(e, 3)}
                  />
                  <ul>
                    {loadingLevels.level3
                      ? Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <li key={`skeleton-${index}`}>
                              <Skeleton
                                height={20}
                                baseColor="var(--phoenix-secondary-bg)"
                                highlightColor="var(--phoenix-emphasis-bg)"
                              />
                            </li>
                          ))
                      : filterCategories(levelThreeCategories, searchTerm[2]).map((cat) => (
                          <li
                            key={cat.id}
                            onClick={() => handleCategoryClick(cat, 3)}
                            className={selectedCategoryIds[2] === cat.id ? 'selected' : ''}
                          >
                            {cat.name}
                          </li>
                        ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomCascader;
