import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_MAGENTO_API_URL, // Replace with your Magento API base URL
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Optional: Add an interceptor to attach token to requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken') || process.env.REACT_APP_ANONYMOUS_TOKEN; // Use anonymous token if no user token
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to handle 401 errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to logout or sign-in page
      localStorage.removeItem('authToken');
      localStorage.removeItem('seller');
      window.location.href = '/pages/authentication/simple/sign-in'; // Adjust the path as needed
    }
    return Promise.reject(error);
  }
);

export default api;
