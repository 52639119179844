import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Card, Form, Spinner, ToastContainer, Toast } from 'react-bootstrap';
import React, { useState } from 'react';
import api from 'api/api';

interface CompanyContactCardProps {
  seller: {
    company_locality: string;
    email: string;
    contact_number: string;
  };
}

const CompanyContactCard: React.FC<CompanyContactCardProps> = ({ seller }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    company_locality: seller.company_locality,
    email: seller.email,
    contact_number: seller.contact_number,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState<'success' | 'error'>('success');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('company_locality', formData.company_locality);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('contact_number', formData.contact_number);

      const authToken = localStorage.getItem('authToken');

      const response = await api.post('/seller/editprofile', formDataToSend, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data[0]?.success) {
        setToastMessage(response.data[0].message);
        setToastVariant('success');
        setShowToast(true);

        // Update the UI with the new data
        const updatedSeller = {
          ...JSON.parse(localStorage.getItem('seller') || '{}'),
          ...formData,
        };
        localStorage.setItem('seller', JSON.stringify(updatedSeller));

        // Update the displayed text with the new data
        seller.company_locality = formData.company_locality;
        seller.email = formData.email;
        seller.contact_number = formData.contact_number;

        setIsEditing(false);
      } else {
        setToastMessage(response.data[0]?.message || 'Failed to update contact info. Please try again.');
        setToastVariant('error');
        setShowToast(true);
      }
    } catch (error) {
      setToastMessage('An error occurred. Please try again.');
      setToastVariant('error');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <div className="border-bottom border-dashed mb-4">
            <h4 className="mb-3 lh-sm lh-xl-1">
              Company Contact Information
              <Button variant="link" className="p-0 ms-3" type="button" onClick={handleEditClick}>
                <FontAwesomeIcon icon={isEditing ? faSave : faEdit} className="text-body-quaternary" />
              </Button>
            </h4>
          </div>
          {isEditing ? (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  name="company_locality"
                  value={formData.company_locality}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="contact_number"
                  value={formData.contact_number}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save changes'}
              </Button>
            </Form>
          ) : (
            <div>
              <div className="pb-7 pb-lg-4 pb-xl-7 mb-4 border-bottom border-dashed">
                <div className="d-flex flex-wrap justify-content-between">
                  <h5 className="text-body-highlight">Address</h5>
                  <p className="text-body-secondary">{seller.company_locality}</p>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between gap-2 mb-3">
                  <h5 className="text-body-highlight mb-0">Email</h5>
                  <a className="lh-1" href={`mailto:${seller.email}`}>
                    {seller.email}
                  </a>
                </div>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <h5 className="text-body-highlight mb-0">Phone</h5>
                  <a href={`tel:${seller.contact_number}`}>{seller.contact_number}</a>
                </div>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      <ToastContainer position="top-end" className="p-3" style={{ position: 'fixed' }}>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastVariant === 'success' ? 'success' : 'danger'}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default CompanyContactCard;
