import Button from 'components/base/Button';
import { useState } from 'react';
import { Col, Form, InputGroup, Row, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { useAuth } from 'providers/AuthProvider';
import api from 'api/api';

const SellerProfileInfo = ({ onUpdateSellerInfo }: { onUpdateSellerInfo: (updatedTitle: string, updatedDescription: string) => void }) => {
  const { seller } = useAuth();
  const [description, setDescription] = useState(seller?.company_description || '');
  const [shopTitle, setShopTitle] = useState(seller?.shop_title || '');
  const [shopUrl, setShopUrl] = useState(seller?.shop_url || '');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState<'success' | 'error'>('success');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const maxLength = 200;

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value.length <= maxLength) {
      setDescription(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('shop_title', shopTitle);
      formData.append('shop_url', shopUrl);
      formData.append('company_description', description);

      const authToken = localStorage.getItem('authToken');

      const response = await api.post('/seller/editprofile', formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      handleApiResponse(response.data);
    } catch (error) {
      console.error('Error updating profile:', error);
      setToastMessage('Failed to update profile. Please try again.');
      setToastVariant('error');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleApiResponse = (data: any) => {
    if (data && data[0]?.success) {
      setToastMessage(data[0].message);
      setToastVariant('success');
      setShowToast(true);

      const updatedSeller = {
        ...JSON.parse(localStorage.getItem('seller') || '{}'),
        ...data[0].data, 
      };
      localStorage.setItem('seller', JSON.stringify(updatedSeller));

      // Update seller info in the SellerProfileCard
      onUpdateSellerInfo(data[0].data.shop_title, data[0].data.company_description);
    } else {
      setToastMessage(data[0]?.message || 'An error occurred. Please try again.');
      setToastVariant('error');
      setShowToast(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row className="gx-3 gy-4 mb-5">
          <Col xs={12} lg={6}>
            <h5 className="text-body-highlight mb-2">Shop Title</h5>
            <Form.Control
              type="text"
              placeholder="Shop Title"
              value={shopTitle}
              onChange={(e) => setShopTitle(e.target.value)}
            />
          </Col>
          <Col xs={12} lg={6}>
            <h5 className="text-body-highlight mb-2">Shop URL</h5>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3">
                {process.env.REACT_APP_SELLER_BASE_URL}/
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Shop URL"
                value={shopUrl}
                readOnly
              />
            </InputGroup>
          </Col>
          <Col xs={12}>
            <h5 className="text-body-highlight mb-2">Shop Description</h5>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Shop Description"
              value={description}
              onChange={handleDescriptionChange}
              maxLength={maxLength}
            />
            <div className="text-end text-muted fs-9">
              {description.length}/{maxLength} characters
            </div>
          </Col>
          <Col xs={12} className="text-start">
            <Button type="submit" variant="primary" className="px-7" disabled={isSubmitting}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save changes'}
            </Button>
          </Col>
        </Row>
      </form>

      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 9999 }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastVariant === 'success' ? 'success' : 'danger'}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default SellerProfileInfo;
