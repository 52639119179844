import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { FileAttachment } from './types';
import Skeleton from 'react-loading-skeleton';

interface CustomImageAttachmentPreviewProps {
  image: string;
  handleClose: () => void;
  className?: string;
  isFirst: boolean;
  roles: string[];
  onRoleChange: (newRole: string, oldRole: string, targetIndex: number) => void;
  onFileUpdate: (updatedData: Partial<FileAttachment>) => void;
  fileData: FileAttachment;
  index: number;
}

const normalizeRole = (role: string) => role.replace(/ /g, '_');

const CustomImageAttachmentPreview: React.FC<CustomImageAttachmentPreviewProps> = ({
  image,
  handleClose,
  className,
  isFirst,
  roles,
  onRoleChange,
  onFileUpdate,
  fileData,
  index,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tempSelectedRoles, setTempSelectedRoles] = useState<string[]>(roles);
  const [altText, setAltText] = useState<string>(fileData.altText || '');
  const [hideFromProductPage, setHideFromProductPage] = useState<boolean>(fileData.hideFromProductPage || false);

  useEffect(() => {
    setTempSelectedRoles(roles);
  }, [roles]);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => {
    setTempSelectedRoles(roles);
    setShowModal(false);
  };

  const handleConfirm = () => {
    const removedRoles = roles.filter((role) => !tempSelectedRoles.includes(role));
    const addedRoles = tempSelectedRoles.filter((role) => !roles.includes(role));

    removedRoles.forEach((role) => onRoleChange('', role, index));
    addedRoles.forEach((role) => onRoleChange(role, '', index));

    onFileUpdate({
      altText,
      hideFromProductPage,
      roles: tempSelectedRoles.reduce((acc, role) => {
        acc[role] = true;
        return acc;
      }, {} as { [role: string]: boolean }),
    });

    setShowModal(false);
  };

  const handleRoleChange = (role: string) => {
    const normalizedRole = normalizeRole(role);
    setTempSelectedRoles((prevRoles) => {
      let updatedRoles: string[];
      if (prevRoles.includes(normalizedRole)) {
        updatedRoles = prevRoles.filter((r) => r !== normalizedRole);
      } else {
        updatedRoles = [...prevRoles, normalizedRole];
      }
      return updatedRoles;
    });
  };

  return (
    <>
      <div
        className={classNames('d-flex flex-column align-items-center', className)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleModalOpen} // Ensure this opens the modal
        style={{ cursor: 'pointer', position: 'relative' }} 
      >
        <div
          className={classNames('position-relative', {
            border: isFirst,
            'border-primary': isFirst,
            'border-3': isFirst,
          })}
          style={{
            borderColor: isFirst ? '#ED6F26' : '',
            width: '150px',
            height: '150px',
            position: 'relative',
          }}
        >
          {fileData.isUploading ? (
            <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
              <Skeleton
                width={150}
                height={150} 
                baseColor="var(--phoenix-secondary-bg)"
                highlightColor="var(--phoenix-emphasis-bg)"
              />
              <div className="position-absolute bottom-0 start-0 w-100">
                <ProgressBar now={fileData.uploadProgress} />
              </div>
            </div>
          ) : (
            <>
              <img
                src={image}
                alt="Attachment Preview"
                className="img-thumbnail"
                style={{ width: '100%', height: '100%', objectFit: 'cover', zIndex: 1 }}
              />
              <button
                type="button"
                className="position-absolute bottom-0 end-0 p-1"
                aria-label="Remove"
                onClick={handleClose}
                style={{
                  border: 'none',
                  background: 'transparent',
                  padding: '0',
                  zIndex: 3, 
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="lg" />
              </button>
            </>
          )}

          {isHovered && !fileData.isUploading && (
            <div
              className="position-absolute top-0 start-0 w-100 h-100"
              style={{
                backgroundColor: 'var(--phoenix-emphasis-bg)',
                opacity: 0.5,
                transition: 'opacity 0.3s ease',
                zIndex: 1,
                pointerEvents: 'none', // Add this to make sure overlay does not block clicks
              }}
            ></div>
          )}
        </div>

        {tempSelectedRoles.length > 0 && !fileData.isUploading && (
          <div className="d-flex flex-wrap justify-content-center mt-2" style={{ maxWidth: '150px' }}>
            {tempSelectedRoles.map((role) => (
              <span key={role} className="badge bg-secondary m-1">
                {role.replace(/_/g, ' ')}
              </span>
            ))}
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="altText">
              <Form.Label>Alt Text</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter alt text"
                value={altText}
                onChange={(e) => setAltText(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="roles" className="mt-3">
              <Form.Label>Role</Form.Label>
              <div>
                {['Base', 'Small', 'Thumbnail', 'OG Image', 'Swatch'].map((role) => (
                  <Form.Check
                    key={role}
                    type="checkbox"
                    id={role}
                    label={role}
                    checked={tempSelectedRoles.includes(normalizeRole(role))}
                    onChange={() => handleRoleChange(role)}
                  />
                ))}
              </div>
            </Form.Group>

            <Form.Group controlId="imageSize" className="mt-3">
              <Form.Label>Image Size</Form.Label>
              <Form.Text>{fileData.size} KB</Form.Text>
            </Form.Group>

            <Form.Group controlId="imageResolution" className="mt-3">
              <Form.Label>Image Resolution</Form.Label>
              <Form.Text>{fileData.resolution}</Form.Text>
            </Form.Group>

            <Form.Group controlId="hideFromProductPage" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Hide from Product Page"
                checked={hideFromProductPage}
                onChange={(e) => setHideFromProductPage(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomImageAttachmentPreview;