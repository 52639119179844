import React, { useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import NavbarBrand from 'components/navbars/nav-items/NavbarBrand';
import NavItems from 'components/navbars/nav-items/NavItems';
import NavItemsSlim from 'components/navbars/nav-items/NavItemsSlim';
import api from 'api/api'; // Assuming api is your custom API request module

const NavbarTopDefault = () => {
  const {
    config: { navbarTopShape, navbarTopAppearance }
  } = useAppContext();

  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await api.get('/seller', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.data && response.data[0]?.success) {
          const sellerData = response.data[0].data;
          localStorage.setItem('seller', JSON.stringify(sellerData));
        } else {
          console.error('Failed to fetch seller data:', response.data[0]?.message || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching seller data:', error);
      }
    };

    fetchSellerData(); // Fetch seller data asynchronously in the background
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <Navbar
      className={classNames('navbar-top fixed-top', {
        'navbar-slim': navbarTopShape === 'slim'
        // 'navbar-darker': navbarTopAppearance === 'darker'
      })}
      expand
      variant=""
      data-navbar-appearance={navbarTopAppearance === 'darker' ? 'darker' : ''}
    >
      <div className="navbar-collapse justify-content-between">
        <NavbarBrand />
        {navbarTopShape === 'default' ? <NavItems /> : <NavItemsSlim />}
      </div>
    </Navbar>
  );
};

export default NavbarTopDefault;
