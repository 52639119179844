import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilChartPie,
  UilShoppingBag,
  UilFileCheckAlt,
  UilMoneyStack
} from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  path?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    path:'/',
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'product-management',
        icon: 'box',
        active: true,
        pages: [
          {
            name: 'add-product',
            path: '/apps/e-commerce/admin/add-product',
            pathName: 'e-commerce-add-product',
            active: true
          },
          {
            name: 'products',
            path: '/apps/e-commerce/admin/products',
            pathName: 'e-commerce-products',
            active: true
          },
          {
            name: 'inventory-request',
            path: '/apps/e-commerce/admin/inventory-request',
            pathName: 'e-commerce-inventory-request',
            active: true,
            pages:[
              {
                name: 'fulfilled-by-Wamia',
                path: '/apps/e-commerce/admin/inventory-request/fbw',
                pathName: 'e-commerce-inventory-request-fbw',
                active: true
              },
              {
                name: 'easy-shipping',
                path: '/apps/e-commerce/admin/inventory-request/es',
                pathName: 'e-commerce-inventory-request-es',
                active: true
              },
              {
                name: 'request-tracking',
                path: '/apps/e-commerce/admin/inventory-request/tracking',
                pathName: 'e-commerce-inventory-request-tracking',
                active: true
              }
            ]
          }
        ]
      },
      {
        name: 'orders-management',
        icon: 'shopping-bag',
        active: true,
        pages:  [
          {
            name: 'orders',
            path: '/apps/e-commerce/admin/orders',
            pathName: 'e-commerce-orders',
            active: true
          },
          {
            name: 'returns',
            path: '/apps/e-commerce/admin/refund',
            pathName: 'e-commerce-refund',
            active: true
          }
        ]
      },
      {
        name: 'billing-management',
        active: true,
        icon: 'credit-card',
        pages:  [
          {
            name: 'transaction',
            path: '/apps/e-commerce/admin/transactions',
            pathName: 'e-commerce-transactions',
            active: true
          },
          {
            name: 'payments',
            path: '/apps/e-commerce/admin/payments',
            pathName: 'e-commerce-payments',
            active: true
          },
          {
            name: 'invoices',
            path: '/apps/e-commerce/admin/invoices',
            pathName: 'e-commerce-invoices',
            active: true
          }
        ]
      }
    ]
  }
];
