import React, { useState } from 'react';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import api from 'api/api';
import { useAuth } from 'providers/AuthProvider';

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    shopTitle: '',
    contactNumber: '',
    description: '',
    firstname: '',
    lastname: '',
    company: '',
    street: '',
    city: '',
    postcode: '',
    region: 'Tunis',
    region_id: 11,
    region_code: 'TU',
    vat_id: '',
  });

  const [errors, setErrors] = useState<string[]>([]);
  const { setSeller, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    // Special case for shopTitle
    if (name === 'shopTitle') {
      setFormData({
        ...formData,
        shopTitle: value,
        company: value, // Copy shopTitle value to company
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const requestData = {
      email: formData.email,
      password: formData.password,
      shopTitle: formData.shopTitle,
      contactNumber: formData.contactNumber,
      description: formData.description,
      billingAddress: {
        firstname: formData.firstname,
        lastname: formData.lastname,
        company: formData.company,
        street: [formData.street],
        city: formData.city,
        postcode: formData.postcode,
        country_id: 'TN', // Fixed country as Tunisia
        region: {
          region: formData.region,
          region_id: formData.region_id,
          region_code: formData.region_code,
        },
        vat_id: formData.vat_id,
      },
    };

    try {
      const response = await api.post('/seller/register', requestData);
      const { seller, token } = response.data[0];
      localStorage.setItem('authToken', token);
      localStorage.setItem('seller', JSON.stringify(seller));
      setSeller(seller);
      setIsAuthenticated(true);
      navigate('/');


    } catch (error: any) {
      const formattedMessage = error.response.data.message.replace(/%(\d+)/g, (match: string, p1: string) => {
        return error.response.data.parameters[parseInt(p1, 10) - 1] || match;
      });
      setErrors([formattedMessage]);      
    }
    
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign Up</h3>
        <p className="text-body-tertiary">Create your account today</p>
      </div>
      {errors.length > 0 && (
        <div className="alert alert-danger">
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="position-relative mt-4">
        <hr className="bg-body-secondary" />
        <div className="divider-content-center">Main Contact Informations</div>
      </div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="firstname">First Name</Form.Label>
          <Form.Control
            id="firstname"
            name="firstname"
            type="text"
            placeholder="First Name"
            value={formData.firstname}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="lastname">Last Name</Form.Label>
          <Form.Control
            id="lastname"
            name="lastname"
            type="text"
            placeholder="Last Name"
            value={formData.lastname}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <Form.Control
            id="email"
            name="email"
            type="email"
            placeholder="name@example.com"
            value={formData.email}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="contactNumber">Contact Number</Form.Label>
          <Form.Control
            id="contactNumber"
            name="contactNumber"
            type="text"
            placeholder="+21698000000"
            value={formData.contactNumber}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">Confirm Password</Form.Label>
              <Form.Control
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="position-relative mt-4">
          <hr className="bg-body-secondary" />
          <div className="divider-content-center">Store Informations</div>
        </div>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="shopTitle">Shop Title</Form.Label>
          <Form.Control
            id="shopTitle"
            name="shopTitle"
            type="text"
            placeholder="Shop Title"
            value={formData.shopTitle}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="company">Company</Form.Label>
          <Form.Control
            id="company"
            name="company"
            type="text"
            placeholder="Company Name"
            value={formData.company}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="description">Description</Form.Label>
          <Form.Control
            id="description"
            name="description"
            as="textarea"
            rows={3}
            placeholder="Description"
            value={formData.description}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="street">Street Address</Form.Label>
          <Form.Control
            id="street"
            name="street"
            type="text"
            placeholder="Street Address"
            value={formData.street}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="city">City</Form.Label>
          <Form.Control
            id="city"
            name="city"
            type="text"
            placeholder="City"
            value={formData.city}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="postcode">Postal Code</Form.Label>
          <Form.Control
            id="postcode"
            name="postcode"
            type="text"
            placeholder="12345"
            value={formData.postcode}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="region">Governorate</Form.Label>
          <Form.Select
            id="region"
            name="region"
            value={formData.region}
            onChange={handleInputChange}
          >
            <option value="Ariana" data-region-id="11" data-region-code="AR">Ariana</option>
            <option value="Béja" data-region-id="22" data-region-code="BE">Béja</option>
            <option value="Ben Arous" data-region-id="13" data-region-code="BA">Ben Arous</option>
            <option value="Bizerte" data-region-id="23" data-region-code="BI">Bizerte</option>
            <option value="Gabès" data-region-id="81" data-region-code="GA">Gabès</option>
            <option value="Gafsa" data-region-id="71" data-region-code="GF">Gafsa</option>
            <option value="Jendouba" data-region-id="32" data-region-code="JE">Jendouba</option>
            <option value="Kairouan" data-region-id="41" data-region-code="KR">Kairouan</option>
            <option value="Kasserine" data-region-id="42" data-region-code="KS">Kasserine</option>
            <option value="Kébili" data-region-id="73" data-region-code="KB">Kébili</option>
            <option value="Kef" data-region-id="33" data-region-code="KE">Kef</option>
            <option value="Mahdia" data-region-id="53" data-region-code="MA">Mahdia</option>
            <option value="Manouba" data-region-id="14" data-region-code="MN">Manouba</option>
            <option value="Medenine" data-region-id="82" data-region-code="ME">Medenine</option>
            <option value="Monastir" data-region-id="52" data-region-code="MO">Monastir</option>
            <option value="Nabeul" data-region-id="21" data-region-code="NA">Nabeul</option>
            <option value="Sfax" data-region-id="61" data-region-code="SF">Sfax</option>
            <option value="Sidi Bouzid" data-region-id="43" data-region-code="SB">Sidi Bouzid</option>
            <option value="Siliana" data-region-id="34" data-region-code="SL">Siliana</option>
            <option value="Sousse" data-region-id="51" data-region-code="SO">Sousse</option>
            <option value="Tataouine" data-region-id="83" data-region-code="TT">Tataouine</option>
            <option value="Tozeur" data-region-id="72" data-region-code="TO">Tozeur</option>
            <option value="Tunis" data-region-id="11" data-region-code="TU">Tunis</option>
            <option value="Zaghouan" data-region-id="22" data-region-code="ZA">Zaghouan</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="vat_id">VAT ID</Form.Label>
          <Form.Control
            id="vat_id"
            name="vat_id"
            type="text"
            placeholder="VAT ID"
            value={formData.vat_id}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            name="termsService"
            id="termsService"
          />
          <Form.Check.Label
            htmlFor="termsService"
            className="fs-9 text-transform-none"
          >
            I accept the <Link to="#!">terms </Link>and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
        <Button variant="primary" className="w-100 mb-3" type="submit">
          Sign up
        </Button>
        <div className="text-center">
          <Link
            to={`/pages/authentication/${layout}/sign-in`}
            className="fs-9 fw-bold"
          >
            Sign in to an existing account
          </Link>
        </div>
      </Form>
    </>
  );
};

export default SignUpForm;
