import React, { useState } from 'react';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import Section from 'components/base/Section';
import CompanyContactCard from 'components/cards/CompanyContactCard';
import SellerProfileCard from 'components/cards/SellerProfileCard';
import SellerProfileDetails from 'components/modules/seller/SellerProfileDetails';
import ChangePasswordModal from 'components/modals/ChangePasswordModal';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from 'providers/AuthProvider';

const SellerProfile = () => {
  const { seller } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const [sellerInfo, setSellerInfo] = useState({
    shop_title: seller?.shop_title || '',
    company_description: seller?.company_description || '',
  });

  if (!seller) return null; // Handle case where seller data is not available

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleUpdateSellerInfo = (updatedTitle: string, updatedDescription: string) => {
    setSellerInfo({
      shop_title: updatedTitle,
      company_description: updatedDescription,
    });
  };

  return (
    <div className="pt-5 mb-9">
      <Section small className="py-0">
        <Row className="align-items-center justify-content-between g-3 mb-4">
          <Col xs="auto">
            <h2 className="mb-0">Seller Profile</h2>
          </Col>
        </Row>
        <Row className="g-3 mb-6">
          <Col xs={12} lg={8}>
            <SellerProfileCard seller={{ ...seller, ...sellerInfo }} onUpdateSellerInfo={handleUpdateSellerInfo} />
          </Col>
          <Col xs={12} lg={4}>
            <CompanyContactCard seller={seller} />
          </Col>
        </Row>
        <SellerProfileDetails onUpdateSellerInfo={handleUpdateSellerInfo} />
      </Section>

      <ChangePasswordModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default SellerProfile;
