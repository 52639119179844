import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Form, Spinner, Alert } from 'react-bootstrap';
import Button from 'components/base/Button';
import Avatar from 'components/base/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import api from 'api/api';

const ResetPasswordForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  
  const [seller, setSeller] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSellerData = async () => {
      try {
        const response = await api.get(`/seller/reset-password?token=${token}`);
        if (response.data && response.data[0]?.success) {
          setSeller(response.data[0].data);
        } else {
          setError('Failed to retrieve seller data. Please try again.');
        }
      } catch (error) {
        setError('An error occurred while fetching seller data.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchSellerData();
    } else {
      setError('Invalid token.');
      setLoading(false);
    }
  }, [token]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const response = await api.post('/seller/reset-password/confirm', {
        email: seller.email,
        token,
        newPassword: password,
      });

      if (response.data && response.data[0]?.success) {
        setIsSuccess(true);
        setResponseMessage(response.data[0].message);
        setTimeout(() => {
          navigate('/');
        }, 5000); // Redirect after 5 seconds
      } else {
        setIsSuccess(false);
        setResponseMessage(response.data[0]?.message || 'Failed to reset password. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setIsSuccess(false);
      setResponseMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger" className="text-center">{error}</Alert>;
  }

  return (
    <div className="text-center mb-6">
      {!responseMessage ? (
        <>
          <h4 className="text-body-highlight">Reset new password</h4>
          <p className="text-body-tertiary">Type your new password</p>

          {seller && (
            <div className="d-flex flex-column align-items-center">
              <Avatar 
                src={`${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${seller.logo_pic || 'default-avatar.png'}`} 
                size="5xl" 
                className="mb-3"
              />
              <h5 className="text-body-highlight">{seller.shop_title}</h5>
              <Form.Group className="text-start w-100 mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  value={seller.email}
                  readOnly 
                />
              </Form.Group>
            </div>
          )}

          <Form className="mt-5" onSubmit={handleSubmit}>
            <Form.Control
              className="mb-2"
              id="password"
              type="password"
              placeholder="Type new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isSubmitting}
            />
            <Form.Control
              className="mb-4"
              id="confirmPassword"
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              isInvalid={password !== confirmPassword}
              disabled={isSubmitting}
            />
            <Form.Control.Feedback type="invalid">
              Passwords do not match.
            </Form.Control.Feedback>
            <Button variant="primary" className="w-100" type="submit" disabled={isSubmitting || password !== confirmPassword}>
              {isSubmitting ? 'Setting Password...' : 'Set Password'}
            </Button>
          </Form>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={isSuccess ? faCheckCircle : faTimesCircle}
            className={isSuccess ? 'text-success' : 'text-danger'}
            size="8x"
          />
          <h4 className="text-body-highlight mt-4">{responseMessage}</h4>
          {isSuccess ? (
            <>
              <p className="text-body-secondary">Redirecting to login in 5 seconds...</p>
              <Link to="/" className="text-primary">Login</Link>
            </>
          ) : (
            <>
              <div className="mt-4">
                <Link to="#" onClick={() => setResponseMessage(null)} className="text-primary me-3">Retry</Link>
                <Link to="/" className="text-primary">Login</Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;
