import React from 'react';
import logoLight from 'assets/img/icons/logo-light.png';
import logoDark from 'assets/img/icons/logo-dark.png';
import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider'; // Assuming you have a context for app theme

interface LogoProps {
  width?: number;
  text?: boolean;
  textClass?: string;
  className?: string;
}

const Logo = ({ width = 27, text = true, textClass, className }: LogoProps) => {
  const { config: { isDark } } = useAppContext(); // Access the theme from context

  return (
    <div className={classNames(className, 'd-flex align-items-center')}>
      <img src={isDark ? logoDark : logoLight} alt="logo" width={width} />
      {text && (
        <p className={classNames(textClass, 'logo-text ms-2')}>phoenix</p>
      )}
    </div>
  );
};

export default Logo;
