import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import ProductVariant from 'components/forms/ProductVariant';
import { Attribute } from 'config/productAttributes';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton loader

interface VariantFormCardProps {
  className?: string;
  variationAttributes: Attribute[];
  handleChange: (attribute_code: string, value: any) => void;
  formValues: { [key: string]: any };
  onCreateVariants: (variants: any[], selectedAttributes: Attribute[]) => void;
  onRemoveVariant: (index: number) => void;
  isLoadingAttributes: boolean; // Add this prop to control the loader
}

const VariantFormCard: React.FC<VariantFormCardProps> = ({
  className,
  variationAttributes,
  handleChange,
  formValues,
  onCreateVariants,
  onRemoveVariant,
  isLoadingAttributes, // New prop to control loading state
}) => {
  const [variants, setVariants] = useState<string[]>(['']);
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([]);

  const maxVariants = 2;

  useEffect(() => {
    // Initialize selected attributes based on formValues
    const attribute_0 = variationAttributes.find(
      (attr) => attr.attribute_code === formValues.variantAttribute_0?.attribute_code
    );
    const attribute_1 = variationAttributes.find(
      (attr) => attr.attribute_code === formValues.variantAttribute_1?.attribute_code
    );
    if (attribute_0) setSelectedAttributes([attribute_0]);
    if (attribute_1) setSelectedAttributes((prev) => [...prev, attribute_1]);

    // If variantAttribute_1 exists, initialize with two variants
    if (formValues.variantAttribute_1) {
      setVariants(['', '']);
    }
  }, [formValues, variationAttributes]);

  const handleAddVariant = () => {
    if (variants.length < maxVariants) {
      setVariants([...variants, '']);
      setSelectedAttributes([...selectedAttributes, {} as Attribute]);
    }
  };

  const handleRemoveVariant = (index: number) => {

    // Update the local state and selected attributes
    setVariants((prevVariants) => prevVariants.filter((_, i) => i !== index));
    setSelectedAttributes((prevAttributes) => prevAttributes.filter((_, i) => i !== index));
    onRemoveVariant(index);
  };

  const handleAttributeChange = (index: number, input_code: string, value: any) => {
    // set the selected attribute for the current variant
    const newSelectedAttributes = [...selectedAttributes];
    const selectedAttribute = variationAttributes.find((attr) => attr.attribute_code === value.attribute_code);
    if (selectedAttribute) {
      newSelectedAttributes[index] = selectedAttribute;
    }
    setSelectedAttributes(newSelectedAttributes);

    handleChange(input_code, value);
  };

  const getFilteredAttributes = (index: number): Attribute[] => {
    if (index === 1 && selectedAttributes[0]) {
      return variationAttributes.filter(attr => attr.attribute_code !== selectedAttributes[0].attribute_code);
    } else if (index === 0 && selectedAttributes[1]) {
      return variationAttributes.filter(attr => attr.attribute_code !== selectedAttributes[1].attribute_code);
    }
    return variationAttributes;
  };

  const handleCreateVariants = () => {
    const generatedVariants = combineSelectedOptions(selectedAttributes);
    onCreateVariants(generatedVariants, selectedAttributes);
  };

  const combineSelectedOptions = (attributes: Attribute[]) => {
    const selectedOptions0 = formValues.selectedAttributeOptions_0 || [];
    const selectedOptions1 = formValues.selectedAttributeOptions_1 || [];

    const allVariants: any[] = [];

    if (attributes.length === 1) {
      selectedOptions0.forEach((option0: any) => {
        allVariants.push({
          [attributes[0].attribute_code]: option0,
        });
      });
    } else if (attributes.length === 2) {
      selectedOptions0.forEach((option0: any) => {
        selectedOptions1.forEach((option1: any) => {
          allVariants.push({
            [attributes[0].attribute_code]: option0,
            [attributes[1].attribute_code]: option1,
          });
        });
      });
    }

    return allVariants;
  };

  return (
    <Card className={className}>
      <Card.Body>
        <h4 className="mb-4">Variants</h4>

        {isLoadingAttributes ? (
          <Skeleton count={variants.length} 
                    className="border-bottom border-translucent border-dashed border-sm-0 border-bottom-xl pb-4"
                    baseColor="var(--phoenix-secondary-bg)"
                    highlightColor="var(--phoenix-emphasis-bg)"
          /> // Show Skeleton while loading
        ) : (
          <Row className="gx-3 gy-4 mb-3">
            {variants.map((variant, index) => (
              <Col xs={12} sm={6} xl={12} key={index}>
                <ProductVariant
                  className="border-bottom border-translucent border-dashed border-sm-0 border-bottom-xl pb-4"
                  index={index}
                  options={getFilteredAttributes(index)}
                  onRemove={() => handleRemoveVariant(index)}
                  handleChange={(input_code, value) => handleAttributeChange(index, input_code, value)}
                  formValues={formValues}
                />
              </Col>
            ))}
          </Row>
        )}

        <Button
          variant="phoenix-primary"
          className="w-100 mb-2"
          onClick={handleAddVariant}
          disabled={variants.length >= maxVariants || variationAttributes.length <= 1}
        >
          Add another option
        </Button>

        <Button
          variant="phoenix-primary"
          className="w-100 mt-2"
          onClick={handleCreateVariants}
        >
          Create Variants
        </Button>
      </Card.Body>
    </Card>
  );
};

export default VariantFormCard;
