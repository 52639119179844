import React, { useEffect, useState } from 'react';
import { Tab, Nav, Col, Row, Form } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { Typeahead } from 'react-bootstrap-typeahead';
import TinymceEditor from 'components/base/TinymceEditor';
import RangeCalendar from 'components/custom/RangeCalendar';

type Option = {
  option_id: string;
  value?: string;
  swatch?: string;
};

type Attribute = {
  attribute_id: string;
  attribute_code: string;
  frontend_label: string;
  is_required: string;
  input_type: string;
  options?: Option[];
};

type AttributeGroup = {
  name: string;
  icon?: string;
  display?: string;
  attributes: Attribute[];
};

type ProductTabsProps = {
  AttributeGroups: AttributeGroup[];
  formValues: { [key: string]: any };
  handleChange: (attribute_code: string, value: any) => void;
  invalidFields: string[];
};

const ProductTabs: React.FC<ProductTabsProps> = ({
  AttributeGroups,
  formValues,
  handleChange,
  invalidFields,
}) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const isExpedieParWamia = formValues.mode_expedition?.[0]?.option_id === "16500";

  useEffect(() => {
    const fromDate = formValues['special_from_date']
      ? new Date(formValues['special_from_date'])
      : null;
    const toDate = formValues['special_to_date']
      ? new Date(formValues['special_to_date'])
      : null;

    if (fromDate && toDate) {
      setDateRange([fromDate, toDate]);
    }
  }, [formValues]);

  const handleDateRangeChange = (dates: Date[] | undefined) => {
    if (dates && dates.length === 2) {
      handleChange('special_from_date', dates[0]);
      handleChange('special_to_date', dates[1]);
      setDateRange([dates[0], dates[1]]);
    }
  };

  return (
    <Tab.Container id="product-tabs" defaultActiveKey={AttributeGroups[0].name}>
      <Row className="g-0 border-top border-bottom">
        <Col xs={12}>
          <Nav variant="tabs" className="border-bottom fs-9 justify-content-start">
            {AttributeGroups.map((AttributeGroup) => (
              <Nav.Item key={AttributeGroup.name}>
                <Nav.Link eventKey={AttributeGroup.name} className="cursor-pointer outline-none d-flex align-items-center">
                  {AttributeGroup.icon && (
                    <FeatherIcon icon={AttributeGroup.icon} size={16} className="me-2 nav-icons" />
                  )}
                  <span>{AttributeGroup.name}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col xs={12}>
          <Tab.Content className="py-3 ps-sm-4">
            {AttributeGroups.map((AttributeGroup) => (
              <Tab.Pane key={AttributeGroup.name} eventKey={AttributeGroup.name}>
                {AttributeGroup.attributes.map((attribute, index, array) => {
                  if (attribute.input_type === 'hidden') {
                    return null;
                  }
                  if (isExpedieParWamia && attribute.attribute_code === 'quantity_and_stock_status') {
                    return null;
                  }
                  const isRequired = attribute.is_required === '1';
                  const inputValue = formValues[attribute.attribute_code] || '';

                  return (
                    <React.Fragment key={attribute.attribute_id}>
                      {['text', 'price', 'weight'].includes(attribute.input_type) && (
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {attribute.frontend_label} {isRequired && <span className="text-danger">*</span>}
                          </Form.Label>
                          <Form.Control
                            type={attribute.input_type === 'text' ? 'text' : 'number'}
                            placeholder={attribute.frontend_label}
                            required={isRequired}
                            value={inputValue}
                            onChange={(e) => handleChange(attribute.attribute_code, e.target.value)}
                            className={invalidFields.includes(attribute.attribute_code) ? 'is-invalid' : ''} 

                          />
                        </Form.Group>
                      )}
                      {attribute.input_type === 'textarea' && (
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {attribute.frontend_label} {isRequired && <span className="text-danger">*</span>}
                          </Form.Label>
                          <TinymceEditor
                            options={{
                              height: '15rem',
                              placeholder: attribute.frontend_label,
                            }}
                            value={inputValue}
                            onChange={(content) => handleChange(attribute.attribute_code, content)}
                          />
                        </Form.Group>
                      )}
                      {['select', 'multiselect'].includes(attribute.input_type) && (
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {attribute.frontend_label} {isRequired && <span className="text-danger">*</span>}
                          </Form.Label>
                          <Typeahead
                            id={attribute.attribute_id}
                            labelKey="value"
                            multiple={attribute.input_type === 'multiselect'}
                            options={attribute.options || []}
                            selected={Array.isArray(inputValue) ? inputValue : inputValue ? [inputValue] : []}
                            onChange={(selected) => handleChange(attribute.attribute_code, selected)}
                            placeholder={attribute.frontend_label}
                            className={invalidFields.includes(attribute.attribute_code) ? 'is-invalid' : ''} // Add invalid class
                            renderMenuItemChildren={(option) => {
                              const opt = option as Option;
                              return (
                                <div className="d-flex align-items-center">
                                  {opt.swatch && (
                                    <div
                                      style={{
                                        backgroundColor: opt.swatch,
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '8px',
                                        border: '1px solid #ccc',
                                      }}
                                    />
                                  )}
                                  <span>{opt.value}</span>
                                </div>
                              );
                            }}
                          />
                        </Form.Group>
                      )}

                      {/* Render RangeCalendar directly after special_price */}
                      {attribute.attribute_code === 'special_price' && (
                        <>
                          <Form.Group className="mb-3">
                            <Form.Label>Special Price Date Range</Form.Label>
                              <RangeCalendar
                                value={dateRange}
                                onChange={handleDateRangeChange}
                                placeholder="Select date range"
                                options={{
                                  mode: "range",
                                  enableTime: true,
                                  minDate: "today",
                                  dateFormat: "Y-m-d h:i",
                                }}
                              />
                          </Form.Group>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default ProductTabs;