import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import api from 'api/api';

interface ChangePasswordModalProps {
  show: boolean;
  handleClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ show, handleClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const { config: { isDark } } = useAppContext();

  const toggleShowPassword = (field: string) => {
    if (field === 'current') {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (field === 'new') {
      setShowNewPassword(!showNewPassword);
    } else if (field === 'confirm') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validatePasswordStrength = (password: string) => {
    setPasswordStrength({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePasswordStrength(value);
  };

  const isPasswordMatch = () => newPassword && confirmPassword && newPassword === confirmPassword;

  const handleSave = async () => {
    if (isPasswordMatch()) {
      try {
        const authToken = localStorage.getItem('authToken');
        const seller = JSON.parse(localStorage.getItem('seller') || '{}');
        const data = {
          customerId: seller.seller_id,
          currentPassword,
          newPassword,
        };

        const response = await api.post('/seller/change-password', data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        // Handle successful response
        console.log('Password changed successfully:', response.data);
        handleClose();
      } catch (error) {
        // Handle error
        console.error('Error changing password:', error);
      }
    } else {
      // Handle error for mismatched passwords
      console.error('Passwords do not match.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered className={isDark ? 'modal-dark' : ''}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="currentPassword">
            <Form.Label>Current Password</Form.Label>
            <InputGroup>
              <Form.Control 
                type={showCurrentPassword ? "text" : "password"} 
                placeholder="Enter current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <InputGroup.Text onClick={() => toggleShowPassword('current')}>
                <FontAwesomeIcon icon={showCurrentPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <InputGroup>
              <Form.Control 
                type={showNewPassword ? "text" : "password"} 
                placeholder="Enter new password"
                value={newPassword}
                onChange={handleNewPasswordChange}
              />
              <InputGroup.Text onClick={() => toggleShowPassword('new')}>
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
            <ul className="text-muted small mt-2">
              <li className={passwordStrength.length ? 'text-success' : ''}>At least 8 characters</li>
              <li className={passwordStrength.uppercase ? 'text-success' : ''}>At least one uppercase letter</li>
              <li className={passwordStrength.number ? 'text-success' : ''}>At least one number</li>
              <li className={passwordStrength.specialChar ? 'text-success' : ''}>At least one special character</li>
            </ul>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm New Password</Form.Label>
            <InputGroup>
              <Form.Control 
                type={showConfirmPassword ? "text" : "password"} 
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                isInvalid={!!confirmPassword && !isPasswordMatch()}
              />
              <InputGroup.Text onClick={() => toggleShowPassword('confirm')}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                Passwords do not match.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={!isPasswordMatch()}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
