import Dropzone from 'components/base/Dropzone';
import ReactSelect from 'components/base/ReactSelect';
import { Col, FloatingLabel, Form } from 'react-bootstrap';
import { CSSObjectWithLabel } from 'react-select';

const options = [
  { value: 'MUSIC', label: 'music' },
  { value: 'CONCEERT', label: 'conceert' },
  { value: 'GREATEST SHOW ON EARTH', label: 'greatest-show-on-earth' }
];

const customStyles = {
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    height: '128px',
    alignItems: 'flex-start' as const, // Ensure the value is compatible
  }),
  dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    display: 'none',
  }),
};

const EventDescriptionForm = () => {
  return (
    <>
      <Col xs={12} className="gy-6">
        <FloatingLabel controlId="eventDescription" label="Description">
          <Form.Control
            as="textarea"
            placeholder="Description"
            style={{ height: '128px' }}
          />
        </FloatingLabel>
      </Col>
      <Col sm={6} className="gy-6">
        <FloatingLabel controlId="floatingOrganizersInput" label="ORGANIZERS">
          <Form.Control type="text" placeholder="ORGANIZERS" />
        </FloatingLabel>
      </Col>
      <Col sm={6} className="gy-6">
        <FloatingLabel controlId="floatingSponsorsInput" label="SPONSORS">
          <Form.Control type="text" placeholder="SPONSORS" />
        </FloatingLabel>
      </Col>
      <Col xs={12} className="gy-6">
        <Dropzone
          accept={{
            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
          }}
        />
      </Col>
      <Col xs={12} className="gy-6">
        <ReactSelect
          options={options}
          isMulti
          placeholder="Add tags"
        />
      </Col>
    </>
  );
};

export default EventDescriptionForm;
