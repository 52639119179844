import React, { useState, CSSProperties } from 'react';
import { Form, Button, Spinner, ToastContainer, Toast } from 'react-bootstrap';
import { useAuth } from 'providers/AuthProvider';
import api from 'api/api';

const SellerBankInfo = () => {
  const { seller } = useAuth();
  const [paymentSource, setPaymentSource] = useState(seller?.payment_source || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState<'success' | 'error'>('success');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentSource(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('payment_source', paymentSource);

      const authToken = localStorage.getItem('authToken');

      const response = await api.post('/seller/editprofile', formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data[0]?.success) {
        setToastMessage(response.data[0].message);
        setToastVariant('success');
        setShowToast(true);

        // Update local storage with the new payment source
        const updatedSeller = {
          ...JSON.parse(localStorage.getItem('seller') || '{}'),
          payment_source: paymentSource,
        };
        localStorage.setItem('seller', JSON.stringify(updatedSeller));
      } else {
        setToastMessage(response.data[0]?.message || 'Failed to update bank information. Please try again.');
        setToastVariant('error');
        setShowToast(true);
      }
    } catch (error) {
      setToastMessage('An error occurred. Please try again.');
      setToastVariant('error');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  // CSS styles for responsive and left-aligned inputs
  const styles: { [key: string]: CSSProperties } = {
    inputGroup: {
      maxWidth: '400px',
      width: '100%',
      marginLeft: '0',
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Bank Identity (RIB)</Form.Label>
        <Form.Control 
          type="text" 
          value={paymentSource} 
          onChange={handleInputChange}
          style={styles.inputGroup}
        />
      </Form.Group>
      <Button type="submit" variant="primary" className="mt-3" disabled={isSubmitting}>
        {isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Save changes'}
      </Button>

      <ToastContainer position="top-end" className="p-3" style={{ position: 'fixed' }}>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastVariant === 'success' ? 'success' : 'danger'}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </form>
  );
};

export default SellerBankInfo;
