import React, { useState, useEffect } from 'react';
import ReactSelect from 'components/base/ReactSelect';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CSSObjectWithLabel } from 'react-select';
import { Attribute, Option } from 'config/productAttributes';
import { option } from 'yargs';

interface ProductVariantProps {
  className?: string;
  index: number;
  options: Attribute[];
  onRemove: () => void;
  handleChange: (attribute_code: string, value: any) => void; // Handle changes for ReactSelect
  formValues: { [key: string]: any };
}

const ProductVariant: React.FC<ProductVariantProps> = ({
  className,
  index,
  options,
  onRemove,
  handleChange,
  formValues
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [selectedReactSelectValues, setSelectedReactSelectValues] = useState<any[]>([]);
  const [selectedAttributeId, setSelectedAttributeId] = useState<string | undefined>();

  useEffect(() => {
    // Initialize the Form.Select with the stored value from formValues
    const storedAttribute = formValues[`variantAttribute_${index}`];
    if (storedAttribute) {
      setSelectedAttributeId(storedAttribute.attribute_id);
      const selectedAttribute = options.find((option) => option.attribute_id === storedAttribute.attribute_id);
      if (selectedAttribute) {
        setSelectedOptions(selectedAttribute.options || []);
      }
    }

    // Initialize ReactSelect with stored values from formValues
    const storedSelectedOptions = formValues[`selectedAttributeOptions_${index}`];
    if (storedSelectedOptions) {
      setSelectedReactSelectValues(
        storedSelectedOptions.map((opt: Option) => ({
          value: opt.value,
          option_id: opt.option_id,
          label: renderOption(opt),
        }))
      );
    }
  }, [formValues, index, options]);

  const customStyles = {
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      height: 'auto',
      alignItems: 'flex-start',
      color: 'var(--phoenix-body-color)',
    }),
    dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: 'none',
    }),
    option: (baseStyles: CSSObjectWithLabel, { data }: any) => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
    }),
    multiValue: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
      color: 'var(--phoenix-body-color)',

    }),
  };

  const handleAttributeSelect = (inputId: string, attributeId: string) => {
    const selectedAttribute = options.find((option) => option.attribute_id === attributeId);
    if (selectedAttribute) {
      setSelectedOptions(selectedAttribute.options || []); // Set the options for ReactSelect
      setSelectedReactSelectValues([]); // Clear ReactSelect content
      handleChange(inputId, selectedAttribute); // Update formValues in AddProduct
    } else {
      setSelectedOptions([]); // Clear ReactSelect options if no attribute is selected
      setSelectedReactSelectValues([]); // Clear ReactSelect content
    }
  };

  const renderOption = (opt: Option) => (
    <div className="d-flex align-items-center">
      {opt.swatch && (
        <div
          style={{
            backgroundColor: opt.swatch,
            width: '16px',
            height: '16px',
            marginRight: '8px',
            border: '1px solid #ccc',
          }}
        />
      )}
      <span>{opt.value}</span>
    </div>
  );

  return (
    <div className={className}>
      <div className="d-flex gap-2 mb-2">
        <h5 className="mb-0 text-body-highlight">Option</h5>
        <Link className="fw-bold fs-9" to="#!" onClick={onRemove}>
          Remove
        </Link>
      </div>
      <Form.Select
        className="mb-3"
        id={`variantAttribute_${index}`}
        value={selectedAttributeId} // Set the initial value
        onChange={(e) => {
          handleAttributeSelect(`variantAttribute_${index}`, e.target.value); // Handle attribute selection
          setSelectedAttributeId(e.target.value); // Update state with the selected value
          setSelectedReactSelectValues([]); // Clear the ReactSelect selection on Form.Select change
          handleChange(`selectedAttributeOptions_${index}`, []); // Clear the formValues entry for ReactSelect
        }}
      >
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.attribute_code} value={option.attribute_id}>
            {option.frontend_label}
          </option>
        ))}
      </Form.Select>
      <ReactSelect
        options={selectedOptions.map((opt) => ({
          value: opt.value,
          option_id: opt.option_id,
          swatch: opt.swatch,
          label: renderOption(opt), // Render the custom option
        }))}
        value={selectedReactSelectValues} // Bind the selected values to state
        isMulti
        placeholder=""
        styles={customStyles as any}
        components={{
          MultiValueLabel: ({ data }) => (
            <div className="d-flex align-items-center">
              {data.swatch && (
                <div
                  style={{
                    backgroundColor: data.swatch,
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                    border: '1px solid #ccc',
                  }}
                />
              )}
              <span>{data.label}</span>
            </div>
          ),
        }}
        onChange={(selected: any) => {
          setSelectedReactSelectValues(selected); // Update selected ReactSelect values
          const selectedOptionsArray = selected.map((opt: { value: string, option_id: string, swatch: string }) => {
            const matchingOption = selectedOptions.find(
              (option) => option.option_id === opt.option_id
              
            );
            return {
              option_id: opt.option_id,
              value: matchingOption?.value || '',
              swatch: matchingOption?.swatch || '',
            };
          });
          handleChange(`selectedAttributeOptions_${index}`, selectedOptionsArray); // Update formValues in AddProduct
        }}
      />
    </div>
  );
};

export default ProductVariant;
