import React, { useMemo, useState, useRef, PropsWithChildren, useEffect } from 'react';
import { Accept, DropEvent, FileRejection, useDropzone, DropzoneProps as ReactDropZoneProps } from 'react-dropzone';
import classNames from 'classnames';
import Button from 'components/base/Button';
import imageIcon from 'assets/img/icons/image-icon.png';
import CustomImageAttachmentPreview from 'components/custom/CustomImageAttachmentPreview';
import { DndProvider, useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FileAttachment } from './types';
import api from 'api/api';
import { C } from '@fullcalendar/core/internal-common';

interface CustomDropzoneProps {
  className?: string;
  size?: 'sm';
  reactDropZoneProps?: ReactDropZoneProps;
  accept?: Accept;
  noPreview?: boolean;
  onDrop?: (newPreviews: any) => void;
  defaultFiles?: FileAttachment[];
  onFileRemove?: (fileName: string) => void;
  onFileUpdate?: (index: number, updatedData: Partial<FileAttachment>) => void;
  onFileIndexChange?: (fromIndex: number, toIndex: number) => void; // Add this prop
}

const ItemType = 'IMAGE';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DraggableImage: React.FC<{ id: string; index: number; moveImage: (dragIndex: number, hoverIndex: number) => void; children: React.ReactNode; }> = ({ id, index, moveImage, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem>({
    accept: ItemType,
    hover(item, monitor: DropTargetMonitor) {
      if (!ref.current || item.index === index) return;
  
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
  
      // Get the cursor position relative to the hovered element
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset!.x - hoverBoundingRect.left;
  
      // Allow drop only when the cursor enters a specific area (e.g., 20px margin) from any edge
      const threshold = 20;
  
      const isWithinYThreshold = 
        hoverClientY < threshold || 
        hoverClientY > hoverBoundingRect.height - threshold;
      const isWithinXThreshold = 
        hoverClientX < threshold || 
        hoverClientX > hoverBoundingRect.width - threshold;
  
      if (isWithinYThreshold || isWithinXThreshold) {
        setTimeout(() => {
          // Perform the drop action after a short delay
          moveImage(item.index, index);
          item.index = index;
        }, 100); // 100ms delay before allowing the drop
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, index, type: ItemType },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </div>
  );
};

const CustomDropzone: React.FC<PropsWithChildren<CustomDropzoneProps>> = ({
  className,
  size,
  onDrop,
  accept,
  noPreview,
  reactDropZoneProps,
  onFileRemove,
  onFileUpdate,
  onFileIndexChange, // Add this prop
  children,
  defaultFiles = [],
}) => {
  const [previews, setPreviews] = useState<FileAttachment[]>(defaultFiles);
  const [imageRoles, setImageRoles] = useState<{ [role: string]: number }>({});

  useEffect(() => {
    initializeRoles(previews);
  }, []);

  const initializeRoles = (files: FileAttachment[]) => {
    const initialRoles: { [role: string]: number } = {};
    files.forEach((file, index) => {
      if (file.roles) {
        Object.entries(file.roles).forEach(([role, hasRole]) => {
          if (hasRole) {
            initialRoles[role] = index;
          }
        });
      }
    });

    // If no roles were set, assign all roles to the first file
    if (Object.keys(initialRoles).length === 0 && files.length > 0) {
      initialRoles.Base = 0;
      initialRoles.Small = 0;
      initialRoles.Thumbnail = 0;
      initialRoles.OG_Image = 0;
      initialRoles.Swatch = 0;
    }
    setImageRoles(initialRoles);

    setPreviews((prevPreviews) =>
      prevPreviews.map((file, index) => {
        const newRoles = Object.keys(initialRoles).reduce((acc, role) => {
          acc[role] = initialRoles[role] === index;
          return acc;
        }, {} as { [role: string]: boolean });
        return { ...file, roles: newRoles };
      })
    );
  };

  const handleRemoveFile = (index: number) => {
    setPreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((_, ind) => ind !== index);

      if (updatedPreviews.length > 0) {
        const removedFile = prevPreviews[index];
        if (removedFile && removedFile.roles) {
          Object.keys(removedFile.roles).forEach((role) => {
            if (removedFile.roles![role]) {
              updatedPreviews[0].roles![role] = true; // Always transfer roles to the first file
            }
          });
        }
      }

      setImageRoles((prevRoles) => {
        const newRoles = { ...prevRoles };
        Object.keys(newRoles).forEach((role) => {
          if (newRoles[role] === index) {
            delete newRoles[role];
            if (updatedPreviews.length > 0) {
              newRoles[role] = 0; // Assign the role to the first file
            }
          } else if (newRoles[role] > index) {
            newRoles[role] -= 1;
          }
        });
        return newRoles;
      });

      return updatedPreviews;
    });

    if (onFileRemove) {
      onFileRemove(previews[index].name);
    }
  };

  const moveImage = (fromIndex: number, toIndex: number) => {
    setPreviews((prevPreviews) => {
      const updatedPreviews = [...prevPreviews];
      const [movedImage] = updatedPreviews.splice(fromIndex, 1);
      updatedPreviews.splice(toIndex, 0, movedImage);

      setImageRoles((prevRoles) => {
        const newRoles = { ...prevRoles };
        Object.keys(newRoles).forEach((role) => {
          if (newRoles[role] === fromIndex) {
            newRoles[role] = toIndex;
          } else if (newRoles[role] > fromIndex && newRoles[role] <= toIndex) {
            newRoles[role] -= 1;
          } else if (newRoles[role] < fromIndex && newRoles[role] >= toIndex) {
            newRoles[role] += 1;
          }
        });
        return newRoles;
      });
      initializeRoles(updatedPreviews);
      // Call the onFileIndexChange prop
      if (onFileIndexChange) {
        onFileIndexChange(fromIndex, toIndex);
      }

      return updatedPreviews;
    });
  };

  const handleRoleChange = (newRole: string, oldRole: string, targetIndex: number) => {
    setImageRoles((prevRoles) => {
      const newRoles = { ...prevRoles };

      if (oldRole) {
        delete newRoles[oldRole];
      }

      if (newRole) {
        newRoles[newRole] = targetIndex;
      }

      const updatedFileData: Partial<FileAttachment> = {
        roles: Object.keys(newRoles).reduce((acc, role) => {
          acc[role] = newRoles[role] === targetIndex;
          return acc;
        }, {} as { [role: string]: boolean }),
      };

      setPreviews((prevPreviews) => {
        const newPreviews = [...prevPreviews];
        newPreviews[targetIndex] = { ...newPreviews[targetIndex], roles: updatedFileData.roles };
        if (oldRole && newRoles[oldRole] !== undefined) {
          const oldRoleIndex = newRoles[oldRole];
          if (oldRoleIndex !== undefined && newPreviews[oldRoleIndex] && newPreviews[oldRoleIndex].roles) {
            newPreviews[oldRoleIndex].roles![oldRole] = false;
          }
        }
        return newPreviews;
      });

      if (onFileUpdate) {
        onFileUpdate(targetIndex, updatedFileData);
      }

      return newRoles;
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      // Step 1: Initialize previews with skeleton loader and initial data
      const newPreviews = acceptedFiles.map((file, index) => ({
        name: file.name,
        preview: '',  // Initially empty, updated after upload
        size: Math.round(file.size / 1024),  // File size in KB
        resolution: '',  // Initially empty, updated after image is processed
        uploadProgress: 0,  // Upload progress starts at 0
        isUploading: true,  // Set upload status to true initially
        roles: index === 0 && previews.length === 0
          ? { Base: true, Small: true, Thumbnail: true, OG_Image: true, Swatch: true }
          : { Base: false, Small: false, Thumbnail: false, OG_Image: false, Swatch: false },  // Initialize roles for the first file
      }));
  
      // Add these previews to the state (to show skeleton and progress)
      setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  
      // Step 2: Upload files and update previews during and after upload
      const updatedPreviews = await Promise.all(
        acceptedFiles.map(async (file, index) => {
          const formData = new FormData();
          formData.append('file', file);
  
          const authToken = localStorage.getItem('authToken');
          let fileUrl = '';
          let resolution = '';
  
          try {
            const response = await api.post('/upload-image', formData, {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: (progressEvent) => {
                if(progressEvent.total) {
                  const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  
                  setPreviews((prevPreviews) =>
                    prevPreviews.map((preview, i) =>
                      i === previews.length + index ? { ...preview, uploadProgress: progress } : preview
                    )
                  );
                }
              },
            });
  
            // Get file URL from the response
            fileUrl = response.data[0]?.url;
  
            // Calculate resolution
            resolution = await calculateImageResolution(file);
  
          } catch (error) {
            console.error('File upload failed:', error);
          }
  
          // Return the updated preview object
          return {
            name: file.name,
            preview: fileUrl,  // Set file URL after upload
            size: Math.round(file.size / 1024),  // File size
            resolution,  // Set resolution
            uploadProgress: 100,  // Set progress to 100 after upload
            isUploading: false,  // Mark upload as complete
            roles: index === 0 && previews.length === 0
              ? { Base: true, Small: true, Thumbnail: true, OG_Image: true, Swatch: true }
              : { Base: false, Small: false, Thumbnail: false, OG_Image: false, Swatch: false },  // Roles for the first image
          } as FileAttachment;
        })
      );
  
      // Step 3: Update state with the final previews
      setPreviews((prevPreviews) => {
        const finalPreviews = [...prevPreviews.slice(0, previews.length), ...updatedPreviews];
        return finalPreviews;
      });
  
      // Step 4: Initialize roles based on the updated previews
      initializeRoles(updatedPreviews);
  
      // Step 5: Call onDrop if it exists
      if (onDrop) {
        onDrop(updatedPreviews);
      }
    },
    accept,
    ...reactDropZoneProps,
  });

  const calculateImageResolution = (file: File): Promise<string> => {
    return new Promise<string>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        resolve(`${img.width}x${img.height} px`);
      };
    });
  };

  const imageOnly = useMemo(() => {
    return Boolean(accept && accept['image/*']);
  }, [accept]);

  return (
    <DndProvider backend={HTML5Backend}>
      {imageOnly && !noPreview && previews.length > 0 && (
        <div className="d-flex flex-wrap gap-2 mb-2">
          {previews.map((file, index) => (
            <DraggableImage
              key={`${file.name}-${index}`}
              index={index}
              id={file.name}
              moveImage={moveImage}
            >
              <CustomImageAttachmentPreview
                image={file.preview}
                handleClose={() => handleRemoveFile(index)}
                isFirst={index === 0}
                roles={Object.keys(imageRoles).filter((role) => imageRoles[role] === index)}
                onRoleChange={(newRole, oldRole) => handleRoleChange(newRole, oldRole, index)}
                onFileUpdate={(updatedData) => {
                  setPreviews((prevPreviews) => {
                    const newPreviews = [...prevPreviews];
                    newPreviews[index] = { ...newPreviews[index], ...updatedData };
                    return newPreviews;
                  });
                  if (onFileUpdate) onFileUpdate(index, updatedData);
                }}
                fileData={file}
                index={index}
              />
            </DraggableImage>
          ))}
        </div>
      )}
      <div
        {...getRootProps()}
        className={classNames(className, 'dropzone', {
          'dropzone-sm': size === 'sm',
        })}
      >
        <input {...getInputProps()} />
        {children ? (
          <>{children}</>
        ) : (
          <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
            Drag your {imageOnly ? 'photo' : 'files'} here{' '}
            <span className="text-body-secondary">or </span>
            <Button variant="link" className="p-0">
              Browse from device
            </Button>
            <br />
            <img
              className="mt-3"
              src={imageIcon}
              width={size === 'sm' ? 24 : 40}
              alt=""
            />
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default CustomDropzone;