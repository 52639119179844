import React, { useEffect, useRef } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

interface RangeCalendarProps {
  options?: flatpickr.Options.Options;
  placeholder?: string;
  value?: [Date | null, Date | null];
  onChange: (selectedDates: Date[]) => void;
}

const RangeCalendar: React.FC<RangeCalendarProps> = ({
  options,
  placeholder = "",
  value,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const flatpickrInstance = useRef<flatpickr.Instance | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      // Filter out null values before passing to flatpickr
      const defaultDate = value ? value.filter(date => date !== null) : undefined;

      flatpickrInstance.current = flatpickr(inputRef.current, {
        mode: "range",
        defaultDate: defaultDate as flatpickr.Options.DateOption[],
        ...options,
        onChange: (selectedDates: Date[]) => {
          onChange(selectedDates);
        },
      });
    }

    return () => {
      if (flatpickrInstance.current) {
        flatpickrInstance.current.destroy();
      }
    };
  }, [options, onChange]);

  useEffect(() => {
    if (flatpickrInstance.current && value) {
      // Filter out null values before setting the date
      const filteredDates = value.filter(date => date !== null) as Date[];
      flatpickrInstance.current.setDate(filteredDates, false);
    }
  }, [value]);

  return (
    <div className="flatpickr-input-container">
      <input
        type="text"
        placeholder={placeholder}
        ref={inputRef}
        className="form-control"
      />
    </div>
  );
};

export default RangeCalendar;