import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUser, faBank, faUndo, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SellerProfileInfo from 'components/forms/SellerProfileInfo';
import SellerBankInfo from 'components/forms/SellerBankInfo';
import SellerReturnPolicy from 'components/forms/SellerReturnPolicy';
import SellerShippingPolicy from 'components/forms/SellerShippingPolicy';
import { Nav, Tab } from 'react-bootstrap';

interface TabLink {
  id: string;
  label: string;
  number?: number;
  icon: IconProp;
  content: JSX.Element;
}

interface SellerProfileDetailsProps {
  onUpdateSellerInfo: (updatedTitle: string, updatedDescription: string) => void; // Add this line
}

const SellerProfileDetails: React.FC<SellerProfileDetailsProps> = ({ onUpdateSellerInfo }) => {
  const tabLinks: TabLink[] = [
    {
      id: 'personal-info',
      label: 'Personal info',
      icon: faUser,
      content: <SellerProfileInfo onUpdateSellerInfo={onUpdateSellerInfo} />, // Pass the prop down
    },
    {
      id: 'bank-info',
      label: 'Bank Information',
      icon: faBank,
      content: <SellerBankInfo />,
    },
    {
      id: 'return-policy',
      label: 'Return Policy',
      icon: faUndo,
      content: <SellerReturnPolicy />,
    },
    {
      id: 'shipping-policy',
      label: 'Shipping Policy',
      icon: faTruck,
      content: <SellerShippingPolicy />,
    }
  ];

  return (
    <Tab.Container defaultActiveKey="personal-info">
      <Nav className="mb-3 pb-1 gap-3 nav-underline flex-nowrap scrollbar">
        {tabLinks.map(item => (
          <Nav.Item key={item.id}>
            <Nav.Link eventKey={item.id} className="text-nowrap">
              <FontAwesomeIcon icon={item.icon} className="me-2" />
              {item.label}{' '}
              {item.number && (
                <span className="text-body-tertiary fw-normal">
                  ({item.number})
                </span>
              )}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <Tab.Content>
        {tabLinks.map(item => (
          <Tab.Pane
            key={item.id}
            eventKey={item.id}
          >
            {item.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

export default SellerProfileDetails;
