import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarUpload from 'components/common/AvatarUpload';
import CoverUpload from 'components/common/CoverUpload';
import React, { useState, CSSProperties } from 'react';
import { Card, Toast, ToastContainer } from 'react-bootstrap';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useAppContext } from 'providers/AppProvider';
import coverPlaceholder from 'assets/img/bg/coverplaceholder.jpg';
import api from 'api/api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface SellerProfileCardProps {
  seller: {
    name: string;
    company_locality: string;
    shop_url: string;
    shop_title: string;
    total_spent?: string;
    last_order?: string;
    total_orders?: string;
    logo_pic?: string;
    banner_pic?: string;
    company_description?: string;
  };
  onUpdateSellerInfo: (updatedTitle: string, updatedDescription: string) => void; // Add this line
}


const SellerProfileCard: React.FC<SellerProfileCardProps> = ({ seller, onUpdateSellerInfo }) => {
  const { config: { isDark } } = useAppContext(); 
  const [coverPhoto, setCoverPhoto] = useState<string | null>(
    seller.banner_pic
      ? `${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${seller.banner_pic}`
      : coverPlaceholder
  );

  const [avatarLoading, setAvatarLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState<'success' | 'error'>('success');

  const handleAvatarChange = async (file: File | null) => {
    if (!file) return;

    setAvatarLoading(true);
    const originalLogo = seller.logo_pic;

    try {
      const formData = new FormData();
      formData.append('logo_pic', file);

      const authToken = localStorage.getItem('authToken');

      const response = await api.post('/seller/editprofile', formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      handleApiResponse(response.data, 'logo_pic', file);
    } catch (error) {
      console.error('Error uploading avatar:', error);
      setToastMessage('Failed to update profile. Please try again.');
      setToastVariant('error');
      setShowToast(true);
      seller.logo_pic = originalLogo; // Revert to the original logo if the upload fails
    } finally {
      setAvatarLoading(false);
    }
  };

  const handleCoverChange = async (file: File | null) => {
    if (!file) return;

    setCoverLoading(true);
    const originalBanner = seller.banner_pic;

    try {
      const formData = new FormData();
      formData.append('banner_pic', file);

      const authToken = localStorage.getItem('authToken');

      const response = await api.post('/seller/editprofile', formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      handleApiResponse(response.data, 'banner_pic', file);
    } catch (error) {
      console.error('Error uploading cover photo:', error);
      setToastMessage('Failed to update cover image. Please try again.');
      setToastVariant('error');
      setShowToast(true);
      seller.banner_pic = originalBanner; // Revert to the original banner if the upload fails
    } finally {
      setCoverLoading(false);
    }
  };

  const handleApiResponse = (data: any, field: string, file: File) => {
    if (data && data[0]?.success) {
      setToastMessage(data[0].message);
      setToastVariant('success');
      setShowToast(true);

      const updatedSeller = {
        ...JSON.parse(localStorage.getItem('seller') || '{}'),
        ...data[0].data, // Update seller object with the new data
      };
      localStorage.setItem('seller', JSON.stringify(updatedSeller));

      if (field === 'banner_pic') {
        setCoverPhoto(`${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${data[0].data.banner_pic}`);
      } else if (field === 'logo_pic') {
        seller.logo_pic = data[0].data.logo_pic;
      }

      // Update the seller's shop title and description if available
      if (data[0].data.shop_title || data[0].data.company_description) {
        onUpdateSellerInfo(data[0].data.shop_title, data[0].data.company_description);
      }
    } else {
      setToastMessage(data[0]?.message || 'An error occurred. Please try again.');
      setToastVariant('error');
      setShowToast(true);
    }
  };

  const styles: { [key: string]: CSSProperties } = {
    coverPhoto: {
      position: 'relative',
      width: '100%',
      height: '232px',
      overflow: 'hidden',
      borderRadius: '0.5rem',
    },
    profileSection: {
      position: 'relative',
      top: '-32px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    },
    statsSection: {
      paddingTop: '20px',
      marginTop: '20px',
      borderTop: '1px solid #eee',
    }
  };

  return (
    <>
      <Card className="h-100">
        <div style={styles.coverPhoto}>
          {coverLoading ? (
            <Skeleton
              height="232px"
              baseColor="var(--phoenix-body-bg)"
              highlightColor={isDark ? '#444' : '#eee'}
            />
          ) : (
            coverPhoto && (
              <CoverUpload
                src={coverPhoto}
                className="w-100"
                onChange={handleCoverChange}
              />
            )
          )}
        </div>
        <Card.Body>
          <div style={styles.profileSection}>
            <div style={{ borderRadius: '50%', border: '4px solid var(--phoenix-emphasis-bg)' }}>
              {avatarLoading ? (
                <Skeleton
                  circle
                  height="9.375rem"
                  width="9.375rem"
                  baseColor="var(--phoenix-body-bg)"
                  highlightColor={isDark ? '#444' : '#eee'}
                />
              ) : (
                <AvatarUpload
                  size="5xl"
                  src={`${process.env.REACT_APP_MEDIA_SERVER_API_URL}/avatar/${seller.logo_pic || 'default-avatar.png'}`}
                  onChange={handleAvatarChange}
                />
              )}
            </div>
            <div style={{ padding: '16px' }}>
              <h3>{seller.shop_title}</h3>
              <p className="text-body-secondary">{seller.company_description}</p>
            </div>
          </div>
        </Card.Body>
      </Card>

      <ToastContainer position="top-end" className="p-3" style={{ position: 'fixed', top: '1rem', right: '1rem', zIndex: 9999 }}>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastVariant === 'success' ? 'success' : 'danger'}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default SellerProfileCard;
